<template>
  <div>
    <div class="d-flex justify-content-start mb-5">
      <button
        @click="exportExcel('exportChangeManagement', `Change Management`)"
        class="btn btn-success btn-md mr-2"
      >
        <i class="fa fa-file-excel" /> Excel
      </button>

      <button @click="exportPDF" class="btn btn-danger btn-md mr-2">
        <i class="fa fa-file-pdf" /> PDF
      </button>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Project Code - Nickname</label>

            <b-form-select
              v-model="filter.code"
              :options="formattedProjectOptions"
              size="lg"
              @change="setNickname($event)"
            ></b-form-select>
          </div>

          <!-- <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Nama Proyek</label>

            <b-form-input
              v-model="filter.nickname"
              disabled
              size="lg"
            ></b-form-input>
          </div> -->

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Change Management Number</label>

            <b-form-select
              v-model="filter.change_management_code"
              :options="formattedChangeManagementOptions"
              size="lg"
              @change="getChangeManagementDetail()"
            ></b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
          <div class="d-flex">
            <button
              class="btn btn-primary btn-md mr-2"
              @click="getChangeManagementDetail()"
            >
              <i class="flaticon2-search-1"></i> Show
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <h5 class="card-title">
          Change Request Info
        </h5>

        <div class="row">
          <div class="mt-2 col-md-3 col-sm-3">
            Change Management Number
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            : {{ getOptionalValue(changeManagementDetail, "cr_number") }}
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            Date Sent To Engineer
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            :
            {{
              moment(
                getOptionalValue(
                  changeManagementDetail,
                  "date_send_to_engineer"
                )
              ).format("DD-MM-YYYY")
            }}
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            Title
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            : {{ getOptionalValue(changeManagementDetail, "title") }}
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            Date Received From Chief Engineer
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            :
            {{
              moment(
                getOptionalValue(
                  changeManagementDetail,
                  "date_received_from_chief"
                )
              ).format("DD-MM-YYYY")
            }}
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            CR Date
          </div>

          <div class="mt-2 col-md-3 col-sm-3">
            :
            {{
              moment(getOptionalValue(changeManagementDetail, "date")).format(
                "DD-MM-YYYY"
              )
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <h5 class="card-title">
          Change Management Item
        </h5>

        <complete-table
          :loading="loading"
          :data="changeManagementDetail ? changeManagementDetail.items : []"
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Number</span>
              </th>
              <th style="min-width: 150px">Type</th>
              <th style="min-width: 150px">Received Date</th>
              <th style="min-width: 150px">Title</th>
              <th style="min-width: 150px">Requester</th>
              <th style="min-width: 200px">Attachment Detail</th>
              <th style="min-width: 150px">Soft File</th>
              <th style="min-width: 150px">Hard File</th>
              <th style="min-width: 150px">Feedback</th>
              <th style="min-width: 150px">Scope</th>
              <th style="min-width: 150px">Plan Date</th>
              <th style="min-width: 150px">Soft File</th>
            </tr>
          </template>

          <template #defaultBody="{ item: data, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ data.code }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.type, "name") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(data.received_date).format("DD-MM-YYYY") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ data.title }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.requestor, "name") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ data.attachment_detail ? "Yes" : "No" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ data.soft_file ? "Yes" : "No" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ data.hard_file ? "Yes" : "No" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ data.feedback ? "Yes" : "No" }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.scope_category, "name") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ moment(data.plan_date).format("DD-MM-YYYY") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ data.soft_filename }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <h5 class="card-title">
          Impacted Design
        </h5>

        <complete-table
          :loading="loading"
          :data="changeManagementDetail ? changeManagementDetail.design : []"
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Numbering</span>
              </th>
              <th style="min-width: 150px">Title</th>
              <th style="min-width: 150px">Type</th>
              <th style="min-width: 150px">Category</th>
              <th style="min-width: 150px">Scope</th>
              <th style="min-width: 200px">Station</th>
              <th style="min-width: 200px">Proposed Plan Date</th>
              <th style="min-width: 200px">Actuation Plan Date</th>
            </tr>
          </template>

          <template #defaultBody="{ item: data, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.dld, "numbering") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.dld, "title") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    getOptionalValue(data.dld, "type", null)
                      ? data.dld.type.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    getOptionalValue(data.dld, "category", null)
                      ? data.dld.category.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    getOptionalValue(data.dld, "scope", null)
                      ? data.dld.scope.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    getOptionalValue(data.dld, "station", null)
                      ? data.dld.station.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  -
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(getOptionalValue(data.dld, "planning_date")).format(
                      "DD-MM-YYYY"
                    )
                  }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <h5 class="card-title">
          PIC
        </h5>

        <complete-table
          :loading="loading"
          :data="changeManagementDetail ? changeManagementDetail.pic : []"
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">initial</span>
              </th>
              <th style="min-width: 150px">Name</th>
              <th style="min-width: 150px">Change Management Item</th>
            </tr>
          </template>

          <template #defaultBody="{ item: data, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.pic, "code") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.pic, "name") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.change_management_item, "code") }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <h5 class="card-title">
          File
        </h5>

        <complete-table
          :loading="loading"
          :data="changeManagementDetail ? changeManagementDetail.file : []"
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">File</span>
              </th>
              <th style="min-width: 150px">Name</th>
              <th style="min-width: 150px">Date Receive</th>
            </tr>
          </template>

          <template #defaultBody="{ item: data, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data, "file") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data, "name") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(getOptionalValue(data, "date_received")).format(
                      "DD-MM-YYYY"
                    )
                  }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <h5 class="card-title">
          Response
        </h5>

        <complete-table
          :loading="loading"
          :data="changeManagementDetail ? changeManagementDetail.response : []"
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Scope</span>
              </th>
              <th style="min-width: 150px">Response Date</th>
            </tr>
          </template>

          <template #defaultBody="{ item: data, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.scope, "name") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(getOptionalValue(data, "date")).format("DD-MM-YYYY")
                  }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <h5 class="card-title">
          Response To Chief
        </h5>

        <complete-table
          :loading="loading"
          :data="
            changeManagementDetail
              ? changeManagementDetail.response_to_chief
              : []
          "
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Scope Category</span>
              </th>
              <th style="min-width: 150px">Response Date</th>
              <th style="min-width: 150px">Status</th>
            </tr>
          </template>

          <template #defaultBody="{ item: data, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data.scope_category, "name") }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(getOptionalValue(data, "date")).format("DD-MM-YYYY")
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ getOptionalValue(data, "status") }}
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <div>
      <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a2"
        pdf-orientation="landscape"
        pdf-content-width="2000px"
        ref="pdfReportingChangeManagement"
        :html-to-pdf-options="{
          filename:
            filter.code + '-' + filter.nickname + '-' + 'Change Management',
          margin: 5,
          jsPDF: {
            unit: 'mm',
            format: 'a2',
            orientation: 'landscape',
          },
        }"
      >
        <section slot="pdf-content">
          <table
            id="exportChangeManagement"
            ref="exportChangeManagement"
            style="width: 100% !important;"
          >
            <tr>
              <td rowspan="4">
                <img :src="logo" style="height: 65px !important;" />
              </td>
            </tr>

            <tr>
              <td colspan="4">
                <h1>
                  PT. Len Railway System
                </h1>
              </td>
              <td colspan="4" rowspan="3">
                <h1>
                  <b>CHANGE MANAGEMENT</b>
                </h1>
              </td>
              <td colspan="2" rowspan="4">
                <div class="pull-right detailHeader">
                  <div class="detailHeaderContent">
                    Day / Date : {{ moment().format("DD-mm-yyyy") }} Time :
                    {{ moment().format("hh:mm") }}<br />
                    Contract Name :
                    {{ getOptionalValue(changeManagementDetail, "cr_number")
                    }}<br />
                    Project Code : {{ filter.code }} <br />
                    Project Name : {{ filter.nickname }} <br />
                  </div>
                </div>
              </td>
            </tr>

            <tr style="margin-top: 0px !important;">
              <td colspan="8">
                <p>
                  Jl. Soekarno Hatta
                </p>
              </td>
            </tr>

            <tr style="margin-top: 0px !important;">
              <td colspan="8">
                <p>
                  Bandung - 40254, Indonesia
                </p>
              </td>
            </tr>

            <tr>
              <td colspan="12">
                <br />
                <br />
                <div style="font-size: 15px;color: black;font-weight: bold;">
                  <hr style="background-color: black;" />
                  Change Request Info
                  <hr style="background-color: black;" />
                </div>
              </td>
            </tr>

            <tr>
              <td style="font-weight: bold;" colspan="2">
                Change Management Number
              </td>
              <td>:</td>
              <td style="width: 25% !important;" colspan="3">
                {{ getOptionalValue(changeManagementDetail, "cr_number") }}
              </td>
              <td></td>
              <td style="font-weight: bold;" colspan="2">
                Date Sent To Enginer
              </td>
              <td>:</td>
              <td style="width: 25% !important;" colspan="2">
                {{
                  getOptionalValue(
                    changeManagementDetail,
                    "date_send_to_engineer"
                  )
                }}
              </td>
            </tr>

            <tr>
              <td style="font-weight: bold;" colspan="2">Title</td>
              <td>:</td>
              <td style="width: 25% !important;" colspan="3">
                {{ getOptionalValue(changeManagementDetail, "title") }}
              </td>
              <td></td>
              <td style="font-weight: bold;" colspan="2">
                Date Received From Chief Engineer
              </td>
              <td>:</td>
              <td style="width: 25% !important;" colspan="2">
                {{
                  getOptionalValue(
                    changeManagementDetail,
                    "date_received_from_chief"
                  )
                }}
              </td>
            </tr>

            <tr>
              <td style="font-weight: bold;" colspan="2">CR Date</td>
              <td>:</td>
              <td style="width: 25% !important;" colspan="3">
                {{ getOptionalValue(changeManagementDetail, "date") }}
              </td>
              <td></td>
            </tr>

            <tr>
              <td colspan="12">
                <div style="font-size: 15px;color: black;font-weight: bold;">
                  <hr style="background-color: black;" />
                  Change Management Item
                  <hr style="background-color: black;" />
                </div>
              </td>
            </tr>

            <tr>
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Number</span>
              </th>
              <th style="min-width: 150px">Type</th>
              <th style="min-width: 150px">Received Date</th>
              <th style="min-width: 150px">Title</th>
              <th style="min-width: 150px">Requester</th>
              <th style="min-width: 200px">Attachment Detail</th>
              <th style="min-width: 150px">Soft File</th>
              <th style="min-width: 150px">Hard File</th>
              <th style="min-width: 150px">Feedback</th>
              <th style="min-width: 150px">Scope</th>
              <th style="min-width: 150px">Plan Date</th>
              <th style="min-width: 150px">Soft File</th>
            </tr>

            <template
              v-for="(data, i) in changeManagementDetail
                ? changeManagementDetail.items
                : []"
            >
              <tr :key="i">
                <th style="min-width: 200px" class="pl-7">
                  <span class="text-dark-75">
                    {{ data.code }}
                  </span>
                </th>
                <th style="min-width: 150px">
                  {{ getOptionalValue(data.type, "name") }}
                </th>
                <th style="min-width: 150px">
                  {{ moment(data.received_date).format("DD-MM-YYYY") }}
                </th>
                <th style="min-width: 150px">
                  {{ data.title }}
                </th>
                <th style="min-width: 150px">
                  {{ getOptionalValue(data.requestor, "name") }}
                </th>
                <th style="min-width: 200px">
                  {{ data.attachment_detail ? "Yes" : "No" }}
                </th>
                <th style="min-width: 150px">
                  {{ data.soft_file ? "Yes" : "No" }}
                </th>
                <th style="min-width: 150px">
                  {{ data.hard_file ? "Yes" : "No" }}
                </th>
                <th style="min-width: 150px">
                  {{ data.feedback ? "Yes" : "No" }}
                </th>
                <th style="min-width: 150px">
                  {{ getOptionalValue(data.scope_category, "name") }}
                </th>
                <th style="min-width: 150px">
                  {{ moment(data.plan_date).format("DD-MM-YYYY") }}
                </th>
                <th style="min-width: 150px">
                  {{ data.soft_filename }}
                </th>
              </tr>
            </template>

            <tr>
              <td colspan="12">
                <div style="font-size: 15px;color: black;font-weight: bold;">
                  <hr style="background-color: black;" />
                  Impacted Design
                  <hr style="background-color: black;" />
                </div>
              </td>
            </tr>

            <tr>
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Numbering</span>
              </th>
              <th style="min-width: 150px">Title</th>
              <th style="min-width: 150px">Type</th>
              <th style="min-width: 150px">Category</th>
              <th style="min-width: 150px">Scope</th>
              <th style="min-width: 200px">Station</th>
              <th style="min-width: 200px">Proposed Plan Date</th>
              <th style="min-width: 200px">Actuation Plan Date</th>
            </tr>

            <template
              v-for="(data, i) in changeManagementDetail
                ? changeManagementDetail.design
                : []"
            >
              <tr :key="i">
                <th style="min-width: 200px" class="pl-7">
                  <span class="text-dark-75">
                    {{ getOptionalValue(data.dld, "numbering") }}
                  </span>
                </th>
                <th style="min-width: 150px">
                  {{ getOptionalValue(data.dld, "title") }}
                </th>
                <th style="min-width: 150px">
                  {{
                    getOptionalValue(data.dld, "type", null)
                      ? data.dld.type.name
                      : "-"
                  }}
                </th>
                <th style="min-width: 150px">
                  {{
                    getOptionalValue(data.dld, "category", null)
                      ? data.dld.category.name
                      : "-"
                  }}
                </th>
                <th style="min-width: 150px">
                  {{
                    getOptionalValue(data.dld, "scope", null)
                      ? data.dld.scope.name
                      : "-"
                  }}
                </th>
                <th style="min-width: 200px">
                  {{
                    getOptionalValue(data.dld, "station", null)
                      ? data.dld.station.name
                      : "-"
                  }}
                </th>
                <th style="min-width: 200px">
                  -
                </th>
                <th style="min-width: 200px">
                  {{
                    moment(getOptionalValue(data.dld, "planning_date")).format(
                      "DD-MM-YYYY"
                    )
                  }}
                </th>
              </tr>
            </template>

            <tr>
              <td colspan="12">
                <div style="font-size: 15px;color: black;font-weight: bold;">
                  <hr style="background-color: black;" />
                  PIC
                  <hr style="background-color: black;" />
                </div>
              </td>
            </tr>

            <tr>
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Initial</span>
              </th>
              <th style="min-width: 150px">Name</th>
              <th style="min-width: 200px">Change Management Item</th>
            </tr>

            <template
              v-for="(data, i) in changeManagementDetail
                ? changeManagementDetail.pic
                : []"
            >
              <tr :key="i">
                <th style="min-width: 200px" class="pl-7">
                  <span class="text-dark-75">
                    {{ getOptionalValue(data.pic, "code") }}
                  </span>
                </th>
                <th style="min-width: 150px">
                  {{ getOptionalValue(data.pic, "name") }}
                </th>
                <th style="min-width: 200px">
                  {{ getOptionalValue(data.change_management_item, "code") }}
                </th>
              </tr>
            </template>

            <tr>
              <td colspan="12">
                <div style="font-size: 15px;color: black;font-weight: bold;">
                  <hr style="background-color: black;" />
                  File
                  <hr style="background-color: black;" />
                </div>
              </td>
            </tr>

            <tr>
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">File</span>
              </th>
              <th style="min-width: 150px">Name</th>
              <th style="min-width: 150px">Date Receive</th>
            </tr>

            <template
              v-for="(data, i) in changeManagementDetail
                ? changeManagementDetail.file
                : []"
            >
              <tr :key="i">
                <th style="min-width: 200px" class="pl-7">
                  <span class="text-dark-75">
                    {{ getOptionalValue(data, "file") }}
                  </span>
                </th>
                <th style="min-width: 150px">
                  {{ getOptionalValue(data, "name") }}
                </th>
                <th style="min-width: 150px">
                  {{
                    moment(
                      getOptionalValue(data, "date_received").format(
                        "DD-MM-YYYY"
                      )
                    )
                  }}
                </th>
              </tr>
            </template>

            <tr>
              <td colspan="12">
                <div style="font-size: 15px;color: black;font-weight: bold;">
                  <hr style="background-color: black;" />
                  Response
                  <hr style="background-color: black;" />
                </div>
              </td>
            </tr>

            <tr>
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Scope</span>
              </th>
              <th style="min-width: 150px">Response Date</th>
            </tr>

            <template
              v-for="(data, i) in changeManagementDetail
                ? changeManagementDetail.response
                : []"
            >
              <tr :key="i">
                <th style="min-width: 200px" class="pl-7">
                  <span class="text-dark-75">
                    {{ getOptionalValue(data.scope, "name") }}
                  </span>
                </th>
                <th style="min-width: 150px">
                  {{
                    moment(getOptionalValue(data, "date")).format("DD-MM-YYYY")
                  }}
                </th>
              </tr>
            </template>

            <tr>
              <td colspan="12">
                <div style="font-size: 15px;color: black;font-weight: bold;">
                  <hr style="background-color: black;" />
                  Response To Chief
                  <hr style="background-color: black;" />
                </div>
              </td>
            </tr>

            <tr>
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Scope Category</span>
              </th>
              <th style="min-width: 150px">Response Date</th>
              <th style="min-width: 150px">Status</th>
            </tr>

            <template
              v-for="(data, i) in changeManagementDetail
                ? changeManagementDetail.response_to_chief
                : []"
            >
              <tr :key="i">
                <th style="min-width: 200px" class="pl-7">
                  <span class="text-dark-75">
                    {{ getOptionalValue(data.scope_category, "name") }}
                  </span>
                </th>
                <th style="min-width: 150px">
                  {{ getOptionalValue(data, "date") }}
                </th>
                <th style="min-width: 150px">
                  {{ getOptionalValue(data, "status") }}
                </th>
              </tr>
            </template>
          </table>
        </section>
      </VueHtml2pdf>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { mapGetters, mapState } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import {
  GET_CHANGE_MANAGEMENT,
  GET_CHANGE_MANAGEMENT_DETAIL,
} from "../../../core/services/store/reporting-change-management.module";
import { optionalData } from "../../../core/helper/object-validation.helper";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";

export default {
  components: { CompleteTable, VueHtml2pdf },
  data() {
    return {
      filter: {
        code: null,
        nickname: null,
        scope: null,
        type: null,
        change_management_code: null,
        jobdesk: [
          "interface",
          "design",
          "planner",
          "drafter",
          "koor",
          "enginer",
          "manager",
          "admin",
        ],
      },
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectDetails.projectDetails?.data,
      loading: (state) => state.reportingChangeManagement.loading,
      changeManagements: (state) =>
        state.reportingChangeManagement.changeManagements?.data,
      changeManagementDetail: (state) =>
        state.reportingChangeManagement.changeManagementDetail?.data,
    }),

    ...mapGetters(["layoutConfig"]),

    logo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: project.code + " - " + project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },

    formattedChangeManagementOptions() {
      let changeManagements = [];

      if (arrayMoreThanOne(this.changeManagements)) {
        changeManagements = this.changeManagements.map((changeManagement) => {
          return {
            value: changeManagement.id,
            text: changeManagement.cr_number,
          };
        });
      }

      return [{ value: null, text: "Choose CR" }, ...changeManagements];
    },
  },
  methods: {
    moment: function() {
      return moment();
    },
    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "interface",
        params: this.filter.jobdesk,
      });
    },

    getChangeManagements() {
      this.$store.dispatch(GET_CHANGE_MANAGEMENT, {
        params: {
          code_project: this.filter.code,
          job_desk: "interface",
        },
      });
    },

    getChangeManagementDetail() {
      this.$store.dispatch(GET_CHANGE_MANAGEMENT_DETAIL, {
        params: {
          changeManagementId: this.filter.change_management_code,
        },
      });
    },

    setNickname(value) {
      const project = this.projects?.find((project) => project?.code === value);

      this.filter.nickname = project?.nickname;

      this.getChangeManagements();
    },

    getOptionalValue(object, key, defaultValue = "-") {
      return optionalData(object, key, defaultValue);
    },

    exportExcel(divId, fileName) {
      console.log(divId, fileName);

      let uri = "data:application/vnd.ms-excel;base64,",
        template =
          '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">' +
          '<head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>' +
          "</head>" +
          "<body><table>{table}</table></body></html>",
        base64 = function(s) {
          return window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function(s, c) {
          return s.replace(/{(\w+)}/g, function(m, p) {
            return c[p];
          });
        };

      let table = null;

      table = document.getElementById(divId);

      const ctx = {
        worksheet: "reporting-change-management" || "Worksheet",
        table: table.innerHTML,
      };

      const link = document.createElement("a");

      link.setAttribute("href", uri + base64(format(template, ctx)));

      link.setAttribute("download", fileName + ".xls");

      link.click();
    },

    exportPDF() {
      this.$refs.pdfReportingChangeManagement.generatePdf();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Report Change Management" },
    ]);

    this.getProjects();
  },
};
</script>

<style>
.detailHeader {
  display: flex;
  height: 100%;
}
.detailHeaderContent {
  display: inline-block;
  align-self: flex-end;
}
</style>
